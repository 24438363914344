.aoi-modal {
}

.modal-title {
	color: rgba(0, 0, 0, 0.75) !important;
	font-family: Inter !important;
	font-size: 16px !important;
	font-style: normal !important;
	font-weight: 600 !important;
	line-height: normal !important;
	letter-spacing: -0.32px !important;
	height: 35px;
}

.title {
	color: rgba(0, 0, 0, 0.75) !important ;
	font-family: Inter !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: normal !important;
	margin-top: 16px;
	margin-bottom: 5px;
}
.location-info {
	color: rgba(0, 0, 0, 0.75) !important;
	font-family: Inter !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: normal !important;
	background: #f5f5f5 !important;
}

.size-block {
	margin: 16px 0px;
}

.date-block {
	display: flex;
	flex-direction: row;
	gap: 16px;
}

.modify-modal-date-picker div {
	background: #f5f5f5 !important;
	color: rgba(0, 0, 0, 0.75) !important;
}
.modify-modal-date-picker button {
	color: rgba(0, 0, 0, 0.75) !important;
}

.modify-modal-date-picker div:first-of-type {
	max-height: 28px !important;
	height: 28px !important;
	padding: 4px 0px !important;
	padding-right: 5px !important;
}
.aoi-select-container {
	width: 100%;
	margin: 0 !important;
	color: rgba(0, 0, 0, 0.75) !important;
	background: #f5f5f5 !important;
}

.aoi-select-container span,
.aoi-select-container svg {
	color: rgba(0, 0, 0, 0.75) !important;
}
.aoi-users-select {
	width: 100%;
	margin: 0 !important;
	color: rgba(0, 0, 0, 0.75) !important;
	background: #f5f5f5 !important;
	max-height: 59px;
}
.aoi-users-select div > span {
	background: #e7e2e2;
	color: rgba(0, 0, 0, 0.75) !important;
}

.aoi-users-select div > svg {
	height: 12px;
	width: 12px;
	fill: black;
	background: transparent;
	color: black !important;
	align-self: baseline;
}
.aoi-users-select div > svg {
	color: rgba(0, 0, 0, 0.75) !important;
}

.users-list {
	background: #f5f5f5 !important;
	color: rgba(0, 0, 0, 0.75) !important;
}

.aoi-users-select ul {
	background: white !important;
}

.access-menu-item {
	display: flex !important;
	padding: 7px 15px 7px 8px !important;
	width: 100%;
	background: white !important;
	color: black !important;
}

ul:has(.access-menu-item) {
	background: white !important;
}

.action-buttons {
	display: flex;
	width: 100%;
	height: 40px;
	padding-top: 8px;
	flex-direction: row;
	justify-content: flex-end;
	margin-top: 20px;
}
